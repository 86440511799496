import React, { useState, useEffect } from "react";
import { isEmpty, get } from "lodash";
import queryString from "query-string";
import ErrorPage from "../components/error-page";
import authentication from "../../auth/react-azure-adb2c";
import adb2cConfig from "../../auth/adb2cConfig";
import { SendClickedOnboardingAuthenticateEvent } from "../helpers/amplitude-analytics";
import { setItem, getItem, codeKey, idKey, emailKey, forgotPasswordKey } from "../helpers/sessionStorage";
import BasePageLayout from "../components/base-page-layout";

const setB2CPreferences = (code, id, email) => {
  if (!isEmpty(code)) {
    setItem(codeKey, code);
    setItem(idKey, id);
    setItem(emailKey, email ?? "");
  }
};

const GetB2CPreferences = (location) => {
  const { search, hash } = location;
  const params = { ...queryString.parse(search), ...queryString.parse(hash) };

  let code = get(params, codeKey, null)
  let id = get(params, idKey, null)
  let email = get(params, emailKey, null)
  setB2CPreferences(code, id, email);

  if (isEmpty(code)) {
    code = getItem(codeKey);
    id = getItem(idKey);
    email = getItem(emailKey);
  }

  return { code, id, email };
};

function HomeScreen(props) {
  const { location, history } = props;
  const isSignedIn = authentication.signedIn();
  const prevCode = getItem(codeKey);
  const { code, id, email } = GetB2CPreferences(location);
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState(null);
  const forgotPassword = getItem(forgotPasswordKey);

  const register = () => {
    SendClickedOnboardingAuthenticateEvent();
    authentication.register(adb2cConfig);
  };

  useEffect(() => {
    if (isSignedIn) {
      if (loading) {
        setLoading(false);
      }
      // if code changed, then signout, set the current code and go to register page
      if (!isEmpty(code) && code !== prevCode) {
        authentication.signOut();
        setB2CPreferences(code, id, email);
        register();
      } else {
        // if already login, it will go to download screen
        history.push("/download");
      }
    } else if (isEmpty(code)) {
      setContent(<ErrorPage isNoCodeError />);
    } else if (forgotPassword) {
      history.push("/login");
      setLoading(true);
    } else {
      register();
    }
  // eslint-disable-next-line
  }, [isSignedIn, code, forgotPassword]);

  return (
    <BasePageLayout
      loading={loading || (!content && !code)}
      content={content}
    />
  );
}

export default HomeScreen;
